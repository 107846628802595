<template>
  <div class="h-full w-full space-y-2">
    <div class="text-sm">
      <div class="pl-2 pt-1.5 text-sm font-medium">{{ title }}</div>
      <ButtonDatepicker
        @change="
          (date, type) => {
            selectedDate = date;
          }
        "
        :default-date="dateRange"
        :disabled-future-date="date => date >= new Date()"
        format="MM/D/YY"
        font-size="text-xs"
        class="m-1"
        :custom-slots="true"
        @close="updateFilters"
        :key="selectedDate"
      />
    </div>
    <div class="flex h-40 items-center justify-center pt-2">
      <BaseLoader :background="800" v-if="loading" />
      <BaseLineChart v-else-if="timeSeries" :options="chartOptions" :series="timeSeries" />
    </div>
  </div>
</template>
<script setup>
import { DateTime } from 'luxon';
import useHttp from '@/composeables/http';
import { onMounted, ref, computed, watch } from 'vue';
import BaseLineChart from '@/components/chart/BaseLineChart.vue';
import ButtonDatepicker from '@/components/ButtonDatepicker.vue';

const $emit = defineEmits(['update:selected-date']);
const $http = useHttp();
const props = defineProps({
  title: {
    type: String,
    default: 'Historical Gas Fee Chart'
  },
  date: { type: Array, default: () => [] }
});

// DATE SELECTION
const selectedDate = ref(props.date);
const loading = ref(true);
const timeSeries = ref([]);
const labelConfig = ref([{ enabled: true, style: { color: '#ffff' } }, { enabled: false }, { enabled: false }]);
const graphColorsScheme = ref(['#BFDBFE', '#3B82F6', '#1E40AF']);

const dateRange = computed(() => {
  if (selectedDate.value.length) {
    return selectedDate.value.map(date => {
      return DateTime.fromISO(date).toFormat('yyyy-MM-dd HH:mm:ss');
    });
  } else {
    let defaultDateRange = [];
    const startOfDay = DateTime.now().toFormat('yyyy-MM-dd 00:00:00');
    const endOfDay = DateTime.now().toFormat('yyyy-MM-dd 23:59:00');
    defaultDateRange.push(startOfDay, endOfDay);
    return defaultDateRange;
  }
});

onMounted(() => {
  getChartData(dateRange.value);
});

async function getChartData(selected_dates) {
  loading.value = true;
  timeSeries.value = [];
  const response = await $http.post('/data/gas_fee_chart', { selected_dates: selected_dates });
  timeSeries.value = response.data.data.map((x, i) => {
    return {
      ...x,
      label: labelConfig.value[i],
      color: graphColorsScheme.value[i],
      lineWidth: 1
    };
  });
  loading.value = false;
}

function updateFilters() {
  let default_date = dateRange.value;
  getChartData(default_date);
}

watch(selectedDate, () => {
  $emit('update:selected-date', selectedDate.value);
});

watch(
  () => props.date,
  (newVal, oldVal) => {
    selectedDate.value = props.date;
    if (oldVal.length == 0) updateFilters();
  }
);

const chartOptions = computed(() => {
  return {
    chart: {
      id: 'historical-gas-fees'
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Date'
      }
    }
  };
});
</script>
